import React from "react";
import { useForm } from "react-hook-form";
import Auth from "../../services/UserService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginAction, setUser } from "../../actionCreators";
import { useTranslation } from "react-i18next";
import { PasswordInput } from "../common/control/PasswordField";
import LoginPageContent from "../common/content/LoginPageContent";
import LdapLogin from "../common/LdapLogin";

export default function App(props) {
  const { t } = useTranslation();
  const { ldap } = props;
  const dispatch = useDispatch();
  const { register, handleSubmit,  formState: { errors }, reset,control } = useForm();

  const onSubmit = async (data) => {
    let response;

    if (ldap) {
      response = await Auth.doUserRequest("user_service/ldaplogin", {
        username: data.email,
        password: data.password,
      });
    } else {
      response = await Auth.doUserRequest("user_service/login", {
        email: data.email,
        password: data.password,
      });
    }

    if (response.success) {
      await Auth.asyncLocalStorage.setItem(
        "user",
        JSON.stringify(response.data)
      );
      await Auth.asyncLocalStorage.setItem(
        "token",
        JSON.stringify(response.token)
      );

      dispatch(loginAction());
      dispatch(setUser(response.data));

      if (props.submit) {
        await props.submit(props.data);
      } else {
        window.location.reload(false);
      }

      toast.success(t(response.message));

      props.close();
    } else {
      toast.error(t(response.message) || t("Network error"));
    }
  };

  return (
    <React.Fragment>
      {props?.showLogin && (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            props.showLogin();
          }}
        >
          <i
            className="fa fa-arrow-left"
            style={{ marginBottom: "20px", fontSize: "20px" }}
          ></i>
        </a>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <LdapLogin
          reset={reset}
          control={control}
          register={register}
          errors={errors}
          {...props}
        />

        <PasswordInput
          label="Password"
          control={control}
          placeholder="Password"
          errors={errors}
          {...register('password',{
            required: t("Password is required"),
          })}
        />
        <LoginPageContent {...props} />
      </form>
    </React.Fragment>
  );
}
