import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import Service from "../../../services/UserService";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Login from "./../../Models/";
import { useSelector } from "react-redux";
import {
  Button,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Loader from "../add/Loader";
import ProductTips from "../productTips";
import CategoryAttributeInput from "../CategoryAttributeInput";
import ChooseCategory from "../ChooseCategory";
import { useTranslation } from "react-i18next";
import MetaTitle from "../../common/MetaTitle";
import {
  getParsedLocalStorageData,
  currencyLabel,
} from "../../../services/Helpers";
import { Input } from "../../common/control/InputField";
import { CategoryAttribute } from "../CategoryAttribute";
let url = "listing_service/products/detail/";

export default function App(props) {
  const { t } = useTranslation();
  const { categories, user, settings } = useSelector((state) => state);
  const { states } = useSelector((state) => ({
    states: state?.locations?.states || [],
  }));
  const { locations } = useSelector((state) => ({
    locations: state?.locations || {},
  }));
  const [product, setProduct] = useState({});

  async function getProductDetail(url) {
    let attributesData = {}
    if (props.slug != null) {
      url = url + props.slug;
      const response = await Service.Request(url, "", "GET");
      if (
        response.data.length != 0 &&
        response.data.product.user._id === user._id
      ) {
        setCount(response.data.product.description.length);
        attributesData = response?.data?.product?.attributes?.reduce((acc, { _id, value }) => {
          acc[_id] = value;
          return acc;
      }, {});
        setProduct({...response.data.product,attributes: attributesData});

        setImagesPath(response.data.imagesPaths);
        setFree(response.data.product.is_free);
        if (response?.data?.bread_crumbs?.length > 3) {
          setParentCategoryTitle(response?.data?.bread_crumbs[1]?.title);
          setChildCategoryTitle(response?.data?.bread_crumbs[2]?.title);
        } else {
          setParentCategoryTitle(response?.data?.bread_crumbs[1]?.title);
        }
      } else {
        navigate("/404");
      }
      return response?.data?.product?{...response.data.product,attributes: attributesData}:{}
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.slug]);


  const [imagesPath, setImagesPath] = useState([]);
  const [free, setFree] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({});

  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [locality, setLocality] = useState("");
  const [cities, setCities] = useState([]);
  const [localities, setLocalities] = useState([]);
  const [count, setCount] = useState(settings?.ad_description_limit);
  const [parentCategoryId, setParentCategoryId] = useState("");

  // parent child category title
  const [parentCategoryTitle, setParentCategoryTitle] = useState("");
  const [childCategoryTitle, setChildCategoryTitle] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (product.category) {
      setSelected(product.category);
      setState(product?.state);
      setCity(product?.city);
      setLocality(product?.locality);
    }
  }, [product]);

  useEffect(() => {
    if (categories.length > 0 && product?._id)
      findCategory(categories, product?.category?._id);
  }, [categories, product?._id]);

  let token = getParsedLocalStorageData("token");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    control,
  } = useForm({ defaultValues: async () => getProductDetail(url) });
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [citiesData, setCitiesData] = useState([]);
  const [disable, setDisable] = useState(false);
  const handleClose = () => setShow(false);
  const handleFree = () => setFree(!free);

  let findCategory = function (data, _id) {
    return data.some(function (e) {
      if (e._id == _id) {
        setSelected(e);
        setSelectedCategory(e);

        return true;
      } else if (e.catChilds) return findCategory(e.catChilds, _id);
    });
  };

  const handleCategory = (e, v) => {
    if (!v) return;

    let { _id } = v;

    findCategory(categories, _id);

    //handleCategory(_id);
    // setSelected(_categoryDetails);
  };

  useEffect(() => {
    var cities = states.map((states) => {
      if (state?._id === states?._id) {
        setCities(states?.cities);
        return states?.cities;
      }
      return null;
    });
    cities = cities.filter((city) => city);
    setCitiesData(cities[0]);
  }, [states, state]);

  useEffect(() => {
    if (citiesData) {
      citiesData.map((cityData) => {
        if (cityData?._id === city?._id) {
          setLocalities(cityData?.localities);
        }
        return null;
      });
    }
  }, [citiesData]);

  const onSubmit = async (data) => {
    token = getParsedLocalStorageData("token");

    if (token) {
      setDisable(true);
      const response = await Service.Request(
        `posting_service/products/${product ? product._id : 0}`,
        {
          title: data.title,
          description: data.description,
          images: imagesPath,
          is_free: free,
          price: free ? 0 : data.price,
          category: selected._id,
          quantity: 1,
          attributes: data.attributes,
          state: state,
          city: city,
          locality: locality ? locality : null,
        },
        "patch"
      );
      if (response.success) {
        setDisable(false);
        // history.push("/category/all");
        toast.success(t(response.message));
      } else {
        toast.error(t(response.message) || t("Network error"));
      }
    } else {
      setFormData(data);
      setShow(true);
    }
  };
  const handleProductChange = (e) => {
    setCount(e.target.value.length);
    const { name, value } = e.target;
    setProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleStateLocation = (event, value) => {
    if (value?.state) {
      const state = {
        _id: value?._id,
        state: value?.state,
      };
      setState(state);
      if (errors?.state?.message) {
        errors.state.message = "";
      }
      setLocalities([]);
      setCities([]);
      setCity([]);
      setCities(value?.cities);
    }
  };
  const handleCityLocation = (event, value) => {
    if (value?.city) {
      const city = {
        _id: value?._id,
        city: value?.city,
      };

      setLocalities([]);
      setLocality({ _id: null, locality: null });
      setCity(city);
      if (errors?.city?.message) {
        errors.city.message = "";
      }
      setLocalities(value?.localities);
    }
  };
  const handleLocalityLocation = (event, value) => {
    if (value?.locality) {
      const locality = {
        _id: value?._id,
        locality: value?.locality,
      };
      setLocality(locality);
      if (errors?.locality?.message) {
        errors.locality.message = "";
      }
    }
  };

  const delete_image = async (index) => {
    setProduct((prevState) => ({
      ...prevState,
      images: product.images.filter((item, i) => i != index),
    }));
    setImagesPath(imagesPath.filter((item, i) => i != index));
  };

  const handleChange = async (event) => {
    let length = event.target.files.length;
    let files = event.target.files;
    let all_paths = [];
    let all_products = [];
    setShowLoader(true);
    for (var i = 0; i < length; i++) {
      let formData = new FormData();
      let imageFile = files[i];
      if (imageFile) {
        if (!imageFile.name.match(/\.(jpg|jpeg|png|PNG|JPG|JPEG)$/)) {
          // toast.error(`${imageFile.name} is not a valid image`);
          toast.error("Selected file is not a valid image");
          return false;
        } else {
          formData.append("image", files[i]);
          const response = await Service.UploadImage(
            "posting_service/products/upload-image",
            formData,
            "POST",
            token
          );

          if (response.success) {
            all_paths.push(response.segmented_path);
            all_products.push(response.path);
          } else {
            toast.error(t(response.message));
          }
        }
      }
    }
    all_paths = imagesPath.concat(all_paths);
    all_products = product.images.concat(all_products);

    setImagesPath(all_paths);
    setProduct((prevState) => ({
      ...prevState,
      images: all_products,
    }));
    setShowLoader(false);
  };

  let ImagesList =
    product &&
    product.images &&
    product.images.map(function (image, index) {
      return (
        <li key={index}>
          <img className="col-sm-12" src={image} />
          <i className="fa fa-trash" onClick={() => delete_image(index)}></i>
        </li>
      );
    });
  return (
    <React.Fragment>
      <MetaTitle title="Edit post" />
      <Modal
        show={show}
        onHide={handleClose}
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Login data={formData} submit={onSubmit} close={handleClose} />
      </Modal>
      <section className={"site-mian " + `${!selected ? "d-none" : ""}`}>
        <div className="container-fluid">
          <div className="upload-file-main">
            <div className="row">
              <div className="col-md-12 col-xl-6 mx-auto mb-lg-0 mb-5">
                <h3>{t("Edit Your Ad")}</h3>
                <p>
                  {t("Details with")} <span className="red-clr">*</span>
                  {t(
                    "are required, other details are not compulsory but you will greatly improve the response to your advert if you include them"
                  )}
                </p>
                <div className="user-upload-product">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h4 className="mb-2">{t("SELECTED CATEGORY")}</h4>
                    <div className="breadcrums">
                      <ul>
                        {parentCategoryTitle && (
                          <li>
                            <a href="#">{parentCategoryTitle}</a>
                          </li>
                        )}

                        {childCategoryTitle && (
                          <li>
                            <a href="#">{childCategoryTitle}</a>
                          </li>
                        )}
                      </ul>
                      <a
                        title={t("Change")}
                        className="change"
                        onClick={(e) => {
                          setSelected("");
                          setChildCategoryTitle("");
                          setParentCategoryTitle("");
                          setParentCategoryId("");
                        }}
                      >
                        {t("Change")}
                      </a>
                    </div>

                    <h5>
                      {t("Photos")} <sup className="red-clr">*</sup>
                    </h5>
                    <div className="uploaded-images">
                      <ul>{ImagesList}</ul>
                      <p>
                        <b>{t("Tip")}</b>:{" "}
                        {t("Add at least 3 images for better response")}
                      </p>
                      <p>{t("Images must be in PNG and JPG format")}</p>
                    </div>
                    <div className="form-group uploaded-images-btn-main">
                      {/* <label className="uploadBtnWrap" htmlFor="thefile">
                        
                        <span className="btn">Browse...</span>
                       
                      </label> */}
                      <label htmlFor="thefile">
                        <Button
                          title={t("Upload")}
                          variant="contained"
                          component="span"
                          className="uploadBtn"
                          startIcon={<CloudUploadIcon />}
                        >
                          {t("Upload")}
                        </Button>
                      </label>
                      {imagesPath.length > 0 ? null : (
                        <span id="fileName">{t("No file selected")}</span>
                      )}
                      <input
                        style={{ display: "none" }}
                        accept=".png,.jpg,.jpeg"
                        id="thefile"
                        // value={imagesPath[0]}
                        type="file"
                        multiple
                        // onChange={handleChange}
                        {...register("image", {
                          validate: (value) => imagesPath.length > 0,
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                      <Loader show={showLoader} />
                      <br />
                      {imagesPath.length < 1 ? (
                        <span className="error-message-ag">
                          {t("Upload at least one image")}
                        </span>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <Input
                        {...register("title", {
                          required: t("Title is required"),
                          minLength: {
                            value: 5,
                            message: t("Title must be 5 characters long"),
                          },
                        })}
                        // value={product?.title || ""}
                        label={t("Title")}
                        placeholder={t("Title")}
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .toString()
                            .replace(/^\s/g, "")
                            .slice(0, 70);
                        }}
                        error={errors?.title && errors?.title?.message !== ""}
                        // helperText={errors?.title ? errors?.title.message : " "}
                        errors={errors}
                        control={control}
                      />

                      {/* <TextField
                        placeholder={t("Title")}
                        name="title"
                        label={`${t("Title")} *`}
                        variant="outlined"
                        value={product?.title || ""}
                        onChange={handleProductChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .toString()
                            .replace(/^\s/g, "")
                            .slice(0, 70);
                        }}
                        autoComplete="off"
                        fullWidth
                        inputRef={register({
                          required: t("Title is required"),
                          minLength: {
                            value: 5,
                            message: t("Title must be 5 characters long"),
                          },
                        })}
                        error={errors?.title && errors?.title.message !== ""}
                        helperText={errors?.title ? errors?.title.message : " "}
                      /> */}
                    </div>
                    <div className="form-group">
                      {/* <TextField
                        id="exampleFormControlTextarea1"
                        label={`${t("Description")} *`}
                        multiline
                        rows={4}
                        aria-label="maximum height"
                        name="description"
                        placeholder={t("Description")}
                        value={product?.description || ""}
                        onChange={handleProductChange}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          maxLength: parseInt(settings?.ad_description_limit),
                        }}
                        variant="outlined"
                        inputRef={register({
                          required: t("Description is required"),
                          minLength: {
                            value: 5,
                            message: t("Description must be 5 characters long"),
                          },
                        })}
                        color="primary"
                        error={
                          errors?.description &&
                          errors?.description.message !== ""
                        }
                        helperText={
                          errors?.description ? errors?.description.message : " "
                        }
                      /> */}

                      <Input
                        {...register("description", {
                          required: t("Description is required"),
                          minLength: {
                            value: 5,
                            message: t("Description must be 5 characters long"),
                          },
                        })}
                        label={t("Description")}
                        placeholder={t("Description")}
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .toString()
                            .replace(/^\s/g, "");
                        }}
                        rows={4}
                        multiline
                        error={
                          errors?.description &&
                          errors?.description.message !== ""
                        }
                        errors={errors}
                        control={control}
                        onChange={(e) => setCount(e.target.value.length)}
                        inputProps={{
                          maxLength: parseInt(settings?.ad_description_limit),
                        }}
                      />
                      <p
                        style={{
                          textAlign: "right",
                          color: "#A0A0A0",
                          marginTop: "-20px",
                        }}
                      >
                        {count}/{settings?.ad_description_limit}
                      </p>
                    </div>
                    {selectedCategory?.attributes?.map((attribute, i) => {
                      const { _id, title, required, min_length, max_length } =
                        attribute;

                      let _rules = {};
                      let _name = attribute.title
                        .toString()
                        .toLowerCase()
                        .replace(" ", "-");

                      if (required) {
                        _rules["required"] = `${title} is required`;
                      }

                      if (title.toLowerCase() == "year") {
                        _rules["minLength"] = {
                          value: 4,
                          message: t("Year should be 4 digits long"),
                        };
                        _rules["maxLength"] = {
                          value: 4,
                          message: t("Year should be 4 digits long"),
                        };
                      } else {
                        _rules["minLength"] = {
                          value: min_length,
                          message: t(
                            `${title} should be ${min_length} digits long`
                          ),
                        };
                        _rules["maxLength"] = {
                          value: max_length,
                          message: t(
                            `${title} should be ${max_length} digits long`
                          ),
                        };
                      }

                      let _attributeValue = "";
                      if (product.attributes && product.attributes.length > 0) {
                        let _productAttribute = product.attributes.find(
                          (attr) => attr["_id"] == _id
                        );
                        if (_productAttribute) {
                          _attributeValue = _productAttribute["value"];
                        }
                      }

                      let _catAttrFieldName = `attributes[${attribute._id}]`;

                      return (
                        <div
                          className="form-group makeDropdown"
                          style={{ marginBottom: "1.2rem" }}
                        >
                          <CategoryAttribute
                            catAttrFieldName={_catAttrFieldName}
                            attribute={attribute}
                            errors={errors}
                            control={control}
                            setValue={setValue}
                            {...register(_catAttrFieldName, { ..._rules })}
                          />
                          {/* <Controller
                            control={control}
                            name={_catAttrFieldName}
                            defaultValue={_attributeValue || ""}
                            rules={_rules}
                            render={({ value, onChange }, { invalid }) => (
                              <CategoryAttributeInput
                                data={attribute}
                                onChange={onChange}
                                inputValue={value || ""}
                                error={!!invalid}
                                helperText={
                                  errors["attributes"] &&
                                  errors["attributes"][attribute._id] &&
                                  errors["attributes"][attribute._id].message
                                }
                              />
                            )}
                          /> */}
                        </div>
                      );
                    })}
                    <div className="form-group is-free-form">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={free}
                            onChange={handleFree}
                            color="primary"
                            name="is_free"
                            inputProps={{ "aria-label": "primary checkbox" }}
                            className="giveitAway"
                          />
                        }
                      />
                      <span className="is_free">
                        {t("Give it away for free")}
                      </span>
                    </div>
                    {free ? (
                      <b />
                    ) : (
                      <div>
                        <FormControl fullWidth variant="outlined">
                          <Controller
                            name="price"
                            control={control}
                            defaultValue=""
                            rules={{ required: t("Price is required") }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                id="EnterPrice"
                                type="number"
                                label={`${t("Price")} *`}
                                style={{ margin: "0 0 20px 0", border: "none" }}
                                placeholder={t("Price")}
                                fullWidth
                                onInput={(e) => {
                                  e.target.value = e.target.value
                                    .replace(/[^0-9\s]/, "")
                                    .toString()
                                    .slice(0, 15);
                                }}
                                margin="normal"
                                autoComplete="off"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {t(
                                        currencyLabel(
                                          locations?.currencySymbol ||
                                            settings?.default_currency
                                        )
                                      )}
                                    </InputAdornment>
                                  ),
                                  inputProps: {
                                    min: 1,
                                  },
                                }}
                                variant="outlined"
                                error={!!errors?.price}
                                helperText={
                                  errors?.price ? errors?.price.message : " "
                                }
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                    )}
                    {(states?.length > 0 && !state) ||
                      (states?.length > 0 && state && (
                        <div className="form-group">
                          <Autocomplete
                            id="states_listing"
                            key={states}
                            options={states ? states : []}
                            getOptionLabel={(state) => {
                              if (state && state?.translation?.length > 0) {
                                return state?.translation[0].labelTranslation;
                              }
                              return state?.state ? state?.state : "";
                            }}
                            fullWidth
                            defaultValue={states.find(
                              (v) => v._id === state?._id
                            )}
                            onChange={handleStateLocation}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("Select State")} *`}
                                variant="outlined"
                                autoComplete="off"
                                {...register("state", {
                                  required: t("Select one option"),
                                })}
                                error={
                                  errors?.state && errors?.state.message !== ""
                                }
                                helperText={
                                  errors?.state ? errors?.state.message : " "
                                }
                              />
                            )}
                          />
                        </div>
                      ))}
                    {cities?.length > 0 && city && (
                      <>
                        <div className="form-group">
                          <Autocomplete
                            id="cities_listing"
                            key={cities}
                            options={cities ? cities : []}
                            getOptionLabel={(city) => {
                              if (city?.translation?.length > 0) {
                                return city?.translation[0].labelTranslation;
                              }
                              return city?.city ? city.city : "";
                            }}
                            fullWidth
                            defaultValue={cities.find(
                              (v) => v._id === city?._id
                            )}
                            onChange={handleCityLocation}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("Select City")} *`}
                                variant="outlined"
                                autoComplete="off"
                                {...register("city", {
                                  required: t("Select one option"),
                                })}
                                error={
                                  errors?.city && errors?.city.message !== ""
                                }
                                helperText={
                                  errors?.city ? errors?.city.message : " "
                                }
                              />
                            )}
                          />
                        </div>
                      </>
                    )}
                    {localities && localities.length > 0 && locality ? (
                      <>
                        <div className="form-group">
                          <Autocomplete
                            id="localities_listing"
                            key={localities}
                            options={localities ? localities : []}
                            getOptionLabel={(locality) => {
                              if (locality?.translation?.length > 0) {
                                return locality?.translation[0]
                                  .labelTranslation;
                              }
                              return locality?.locality
                                ? locality.locality
                                : "";
                            }}
                            fullWidth
                            defaultValue={localities.find(
                              (v) => v._id === locality?._id
                            )}
                            onChange={handleLocalityLocation}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("Select Locality")} *`}
                                variant="outlined"
                                autoComplete="off"
                                {...register("locality", {
                                  required: t("Select one option"),
                                })}
                                error={
                                  errors?.locality &&
                                  errors?.locality.message !== ""
                                }
                                helperText={
                                  errors?.locality
                                    ? errors?.locality.message
                                    : " "
                                }
                              />
                            )}
                          />
                        </div>
                      </>
                    ) : null}
                    <FormControl variant="outlined">
                      <Button
                        title={t("Update")}
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        className="btnBox"
                        disabled={disable}
                      >
                        {t("Update")}
                      </Button>
                    </FormControl>
                  </form>
                </div>
              </div>
              <ProductTips />
            </div>
          </div>
        </div>
      </section>
      {!selected && (
        <ChooseCategory
          categories={categories}
          setParentCategoryTitle={setParentCategoryTitle}
          setChildCategoryTitle={setChildCategoryTitle}
          handleCategory={handleCategory}
          setParentCategoryId={setParentCategoryId}
        />
      )}
    </React.Fragment>
  );
}
